import {
	parseDate
} from "../../utils/convert.js"
export default {
	name: 'Event',
	data() {
		return {
			pickerOptions: {
				disabledDate(time) {
					let curDate = (new Date()).getTime();
					let three = 89 * 24 * 3600 * 1000;
					let threeMonths = curDate - three;
					return time.getTime() > Date.now() || time.getTime() < threeMonths;
				},
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近两个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			searchTime: null,
			chartInstance: null,
			getPatientDetailUuid: '',
			//时间选择器选择时间差
			iDays: 90,
			eventTableData: [],
			OverviewData: [],
			loading: true,

		}
	},
	props: {
		puid: String
	},
	created() {
		this.getSearchTime();
	},
	methods: {
		//默认选择90天日期
		getSearchTime() {
			let nowDate = new Date();
			let endTime = parseDate(nowDate);
			let startTime = parseDate(new Date(nowDate.setDate(nowDate.getDate() - 13)));
			this.searchTime = [startTime, endTime];
			this.doSearchTime();
		},
		doPDFExport() {
			//个人信息
			document.querySelector('.el-collapse-item__wrap').style.display='block';
			document.querySelector('.el-tabs__header').style.display='none';
			document.querySelector('.pdfshow').style.display='none';
			document.querySelector('.pdftitle').style.display='block';
			document.querySelector('.event-totalwarp').style.boxShadow='inherit';
			console.log(document.querySelector('#forPDF'))
			this.$PDFSave(document.querySelector('#forPDF'), '事件统计');
			document.querySelector('.el-collapse-item__wrap').style.display='none';
			document.querySelector('.event-totalwarp').style.boxShadow='0 0 8px #ddd';
			document.querySelector('.pdfshow').style.display='block';
			document.querySelector('.pdftitle').style.display='none';
			document.querySelector('.el-tabs__header').style.display='block';
		},
		//点击查询按钮按照日期查询
		doSearchTime() {
			const entMeasureTime = new Date(Date.parse(this.searchTime[1].replace(/-/g, "/")));
			const startMeasureTime = new Date(Date.parse(this.searchTime[0].replace(/-/g, "/")));
			this.iDays = parseInt(Math.abs(entMeasureTime.getTime() - startMeasureTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;
			var params = {
				userUuid: this.puid,
				endTime: this.searchTime[1] + ' 23:59:59',
				startTime: this.searchTime[0] + ' 00:00:00',
			}
			this.$data.OverviewData = Object.assign([], this.$data.OverviewData); //清空对象
			this.$axios.post('/api/web/event/getAll', params).then(response => {
				if (response.data.code == 200) {
					// post 成功，response.data 为返回的数据
					this.OverviewData = response.data.data;
					console.log(this.OverviewData)
					this.eventTableData = response.data.data.listDTOs;
					if (this.eventTableData !== null) {
						this.eventTableData.forEach(item => {
							item.key = parseInt(item.time)
						})
						this.eventTableData.sort(this.compare('key'));
					}
				}
				this.loading = false;
			}).catch(error => {
				// 请求失败
				console.log(error)
				/* this.$message({
					message: "请求失败121",
					type: "error"
				}); */
			})
		},
		compare(key) {
			return function(a, b) {
				var val1 = a[key];
				var val2 = b[key];
				return val1 - val2;
			}
		}
	},
};
