import { render, staticRenderFns } from "./Stats.vue?vue&type=template&id=ddaf4fc0&scoped=true&"
import script from "./Stats.js?vue&type=script&lang=js&"
export * from "./Stats.js?vue&type=script&lang=js&"
import style0 from "./Stats.vue?vue&type=style&index=0&id=ddaf4fc0&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddaf4fc0",
  null
  
)

export default component.exports