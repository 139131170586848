import {
	parseDate
} from "../../utils/convert.js"
export default {
	name: 'Distribution',
	data() {
		return {
			pickerOptions: {
				disabledDate(time) {
					let curDate = (new Date()).getTime();
					let three = 59 * 24 * 3600 * 1000;
					let threeMonths = curDate - three;
					return time.getTime() > Date.now() || time.getTime() < threeMonths;
				},
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近两个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			searchTime: null,
			chartInstance: null,
			//时间选择器选择时间差
			iDays: 90,
			Width: '',
			loading: true,

			chartData: [],
			avg: 0,
			std: 0,
			kurtosis: 0,
			skewness: 0
		}
	},
	props: {
		puid: String
	},
	created() {
		window.addEventListener('resize', this.innerWidth);
		this.getWidth();
		this.getSearchTime();
	},
	destroyed() {
		window.removeEventListener('resize', this.innerWidth)
	},
	computed: {

	},
	methods: {
		dataOrderBy(ddd) {
			const data = ddd.concat([]); // 为防止 sort 方法修改原数组，对原数组进行拷贝，操作副本。
			return data.sort((a, b) => a - b)
		},
		average(ddd) {
			if (ddd.length === 0) return 0;
			let sum = ddd.reduce((prev, curr) => prev + curr);
			let avg = sum / ddd.length;

			return avg;
		},
		standardDeviation(ddd) {
			if (ddd.length === 0) return 0;
			// 平均值
			let avg = this.average(ddd);
			this.avg = avg.toFixed(1);
			// 1. 偏差
			const dev = ddd.map(x => x - avg);
			// 2. 偏差平方和
			const sumOfSquOfDev = dev.map(x => x * x).reduce((x, y) => x + y);
			// 3. 方差
			const variance = sumOfSquOfDev / (ddd.length - 1);
			// 4. 返回标准差
			return Math.sqrt(variance);
		},
		dataAfterClean(ddd) {
			let res = {};
			for(let i = 0; i < ddd.length; i++) {
				let key = ddd[i];
				if(key !== 'NaN' && parseInt(key) === 0) {
					key = "0.0";
				}

				if(res[key]) {
					res[key] += 1;
				} else {
					res[key] = 1
				}
			}
			return res;
		},
		dataAfterCleanX(ddd) {
			let afd = this.dataAfterClean(ddd);
			return Object.keys(afd).sort((a, b) => a - b).map(t => parseFloat(t)
				.toFixed(1))
		},
		dataAfterCleanY(ddd) {
			let r = [];
			let afx = this.dataAfterCleanX(ddd);
			let af = this.dataAfterClean(ddd);
			for(let i = 0; i < afx.length; i++) {
				r.push(af[afx[i]])
			}
			return r;
		},
		normalDistribution(ddd) {
			let res = [];
			let afx = this.dataAfterCleanX(ddd);
			const a = this.standardDeviation(ddd);
			const u = this.average(ddd)
			this.std = a.toFixed(1);
			for (let i = 0; i < afx.length; i++) {
				const x = afx[i];

				const y = (1 / (Math.sqrt(2 * Math.PI) * a)) * (Math.exp(-1 * ((x - u) * (x - u)) / (2 *
					a * a)));
				res.push(y)
				if (x == 11.8)
					console.log(y,138) // 正态分布峰值，用于验证
			}
			return res;
		},
		showSkewnessAndKurtosis(p) {
			this.$axios.post('/api/web/patientCgmData/findKurtosisAndSkewness', p).then(res => {
				// post 成功，response.data 为返回的数据
				if(res.data.code === 200) {
					this.kurtosis = res.data.data.kurtosis;
					this.skewness = res.data.data.skewness;
				}
				this.loading= false;
			}).catch(error => {
				// 请求失败
				console.log(error)
				/* this.$message({
					message: "请求失败",
					type: "error"
				}); */
			})
		},
		getWidth() {
			this.Width = window.innerWidth - 282 + 'px';
		},
		//默认选择90天日期
		getSearchTime() {
			let nowDate = new Date();
			let endTime = parseDate(nowDate);
			let startTime = parseDate(new Date(nowDate.setDate(nowDate.getDate() - 13)));
			this.searchTime = [startTime, endTime];
			this.doSearchTime();
		},
		doPDFExport() {
			document.querySelector('.el-collapse-item__wrap').style.display='block';
			document.querySelector('.el-tabs__header').style.display='none';
			document.querySelector('.pdfshow').style.display='none';
			document.querySelector('.pdftitle').style.display='block';
			console.log(175,document.querySelector('#forPDF'))
			this.$PDFSave(document.querySelector('#forPDF'), '血糖分布');
			document.querySelector('.el-collapse-item__wrap').style.display='none';
			document.querySelector('.pdfshow').style.display='block';
			document.querySelector('.pdftitle').style.display='none';
			document.querySelector('.el-tabs__header').style.display='block';
		},
		//点击查询按钮按照日期查询
		doSearchTime() {
			const entMeasureTime = new Date(Date.parse(this.searchTime[1].replace(/-/g, "/")));
			const startMeasureTime = new Date(Date.parse(this.searchTime[0].replace(/-/g, "/")));
			this.iDays = parseInt(Math.abs(entMeasureTime.getTime() - startMeasureTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;
			let params = {
				userUuid: this.puid,
				entMeasureTime: this.searchTime[1] + ' 23:59:59',
				startMeasureTime: this.searchTime[0] + ' 00:00:00',
			}
			this.chartData.splice(0, this.chartData.length); //清空数组
			this.$axios.post('/api/web/patientCgmData/findUserGlucoseAll', params).then(response => {
				// post 成功，response.data 为返回的数据

				// test
				// let arr = response.data.data.concat(response.data.data).concat(response.data.data).concat(response.data.data);
				let arr = response.data.data;
				console.log("arr", this.chartData.length);
				this.chartData = this.dataOrderBy(arr);
				if(this.chartData != null && this.chartData.length > 0) {
					this.initchart();
					// 峰度和偏度
					this.showSkewnessAndKurtosis(params);
				}else{
					this.loading= false;
				}
			}).catch(error => {
				// 请求失败
				console.log(error)
				/* this.$message({
					message: "请求失败111",
					type: "error"
				}); */
			})
		},
		//初始化echartInstance对象
		initchart() {
			this.chartInstance = this.$echarts.init(this.$refs.seller_ref);
			const initOption = {
				tooltip: {
					// trigger: 'axis'
				},
				title: {
					text: "血糖分布直方图 ",
					// left: "63",
					// top: "20",
					textStyle: {
						fontSize: 16,
					},
				},
				legend: {
					data: ['f(x)']
				},
				xAxis: [{
					data: this.dataAfterCleanX(this.chartData),
				}],
				yAxis: [{
					type: 'value',
					name: '频数',
					position: 'left',
					// 网格线
					splitLine: {
						show: false
					},
					axisLine: {
						lineStyle: {
							color: '#4870FF'
						}
					},
					axisLabel: {
						formatter: '{value}'
					}
				}, {
					type: 'value',
					name: '概率',
					position: 'right',
					// 网格线
					splitLine: {
						show: false
					},
					axisLine: {
						lineStyle: {
							color: 'black'
						}
					},
					axisLabel: {
						formatter: '{value}'
					}
				}],
				series: [{
					name: '源数据', // y 轴名称
					type: 'bar', // y 轴类型
					yAxisIndex: 0,
					barGap: 0,
					barWidth: 27,
					itemStyle: {
						normal: {
							show: true,
							color: '#4870FF', //柱子颜色
							borderColor: '#4870FF' //边框颜色
						}
					},
					data: this.dataAfterCleanY(this.chartData),
				}, {
					name: '正态分布', // y 轴名称
					type: 'line', // y 轴类型
					symbol: 'none', //去掉折线图中的节点
					smooth: true, //true 为平滑曲线
					yAxisIndex: 1,
					data: this.normalDistribution(this.chartData),
					itemStyle: {
						normal: {
							color: '#FAD555'
						}
					}
				}]
			};
			this.chartInstance.setOption(initOption);
			window.addEventListener("resize", () => {
				this.chartInstance.resize()
			});
		}
	},
};
