import StatsDaily from "./stats/StatsDaily.vue";
import StatsWeekly from "./stats/StatsWeekly.vue";
import { parseDate } from "../../utils/convert.js"
export default {
	name: 'Stats',
	components: {
		StatsDaily,
		StatsWeekly
	},
	data() {
		return {
			pickerOptions: {
				disabledDate(time) {
					let curDate = (new Date()).getTime();
					let three = 89 * 24 * 3600 * 1000;
					let threeMonths = curDate - three;
					return time.getTime() > Date.now() || time.getTime() < threeMonths;
				},
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近两个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			searchTime: null,
			//时间选择器选择时间差
			iDays: 60,
			statsActiveName: 'daily',

			startTime: '',
			endTime: '',
			doSearch:'',

			pdfTitle: ''
		}
	},
	props: {
		puid: String
	},
	created() {
		this.getSearchTime();
	},
	methods: {
		//默认选择60天日期
		getSearchTime() {
			let nowDate = new Date();
			let endTime = parseDate(nowDate);
			let startTime = parseDate(new Date(nowDate.setDate(nowDate.getDate() - 13)));
			this.searchTime = [startTime, endTime];
			this.doSearchTime();
		},
		doSearchTime(){
			const entMeasureTime = new Date(Date.parse(this.searchTime[1].replace(/-/g, "/")));
			const startMeasureTime = new Date(Date.parse(this.searchTime[0].replace(/-/g, "/")));
			this.iDays = parseInt(Math.abs(entMeasureTime.getTime() - startMeasureTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;
			this.startTime = this.searchTime[0];
			this.endTime = this.searchTime[1];
			this.doSearch = new Date().getTime();
		},
		doPDFExport() {
			document.querySelector('.el-collapse-item__wrap').style.display='block';
			document.querySelector('.el-tabs__header').style.display='none';
			document.querySelector('.pdfshow').style.display='none';
			document.querySelector('.pdftitle').style.display='block';
			if(this.statsActiveName === 'daily') {
				this.$PDFSave(document.querySelector('#forPDF'), '每日统计');
			} else {
				this.$PDFSave(document.querySelector('#forPDF'), '每周统计');
			}
			document.querySelector('.el-collapse-item__wrap').style.display='none';
			document.querySelector('.pdfshow').style.display='block';
			document.querySelector('.pdftitle').style.display='none';
			document.querySelector('.el-tabs__header').style.display='block';
			
			
		}

	},
};
