export default {
	name: "StatsDaily",
	data() {
		return {
			loading: true,

			statsDailyNum: null,
			tirChart: [],
			idStatsDailyTir: null,
			chartInstance: {},
			statsDaily: []
		}
	},
	props: {
		puid: String,
		startTime: String,
		endTime: String
	},
	created() {
		this.doSearch();
	},

	methods: {
		doSearch() {
			let startMeasureTime = this.startTime;
			let endMeasureTime = this.endTime;
			let params = {
				userUuid: this.puid,
				endMeasureTime: endMeasureTime + ' 23:59:59',
				startMeasureTime: startMeasureTime + ' 00:00:00',
			}
			this.tirChart.splice(0, this.tirChart.length); //清空数组
			this.statsDaily.splice(0, this.statsDaily.length); //清空数组
			this.$axios.post('/api/web/patientCgmData/findStatsDay', params)
				.then(res => {
					if (res.data.code === 200) {
						this.statsDaily = res.data.data;
						for (let i = 0; i < this.statsDaily.length; i++) {
							var timeQuantum = parseInt(this.statsDaily[i].hh);
							if (timeQuantum == 11) {
								this.statsDaily[i].timeQuantum = timeQuantum + 'am' + ' - 12pm'
							} else if (timeQuantum == 12) {
								this.statsDaily[i].timeQuantum = timeQuantum + 'pm - ' + '1pm'
							} else if (timeQuantum < 11) {
								this.statsDaily[i].timeQuantum = timeQuantum + 'am - ' + (timeQuantum + 1) + 'am'
							} else if (timeQuantum > 11) {
								this.statsDaily[i].timeQuantum = (timeQuantum - 12) + 'pm - ' + (timeQuantum - 11) +
									'pm'
							} else if (timeQuantum == 23) {
								this.statsDaily[i].timeQuantum = (timeQuantum - 12) + 'pm - ' + '12am'
							}
						}
						this.$nextTick(() => {
						for (let i = 0; i < this.statsDaily.length; i++) {
							
							this.tirChart = [{
									value: parseFloat(this.statsDaily[i].veryLowPrecision),
								},
								{
									value: parseFloat(this.statsDaily[i].lowPrecision),
								},
								{
									value: parseFloat(this.statsDaily[i].inRangePrecision),
								},
								{
									value: parseFloat(this.statsDaily[i].highPrecision),
								},
								{
									value: parseFloat(this.statsDaily[i].veryHighPrecision),
								},
							];
							this.statsDailyNum = i;
							this.idStatsDailyTir = 'statsDailyTir' + [i];
							this.initchart();
						}
						this.loading = false;
						});
					}
				}).catch(error => {
					console.log(error)
					// 请求失败
					/* this.$message({
						message: error,
						type: "error"
					}); */
				})
		},
		initchart() {
			this.chartInstance = this.$echarts.init(document.getElementById(this.idStatsDailyTir));
			const initOption = {
				tooltip: {
					trigger: 'item'
				},
				series: [{
					name: '',
					type: 'pie',
					avoidLabelOverlap: false,
					label: {
						show: false,
						position: 'center'
					},
					labelLine: {
						show: false
					},
					color: ['#B93333', '#FF6060', '#26AB40', '#FAD555', '#FFA74A'],
					data: this.tirChart
				}]
			};
			this.chartInstance.setOption(initOption);
			window.addEventListener("resize", () => {
				this.chartInstance.resize();
			});
		}
	}
}
