import {
	xData
} from "../../utils/chartXData.js"
import {
	yData
} from "../../utils/chartXData.js"
import {
	parseDate
} from "../../utils/convert";
import {
	groupby
} from "../../utils/reportTools.js";

export default {
	name: 'Weekly',
	data() {
		return {
			pickerOptions: {
				disabledDate(time) {
					let curDate = (new Date()).getTime();
					let three = 89 * 24 * 3600 * 1000;
					let threeMonths = curDate - three;
					return time.getTime() > Date.now() || time.getTime() < threeMonths;
				},
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近两个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			searchTime: null,
			chartInstance: null,
			//时间选择器选择时间差
			iDays: 60,
			Width: '',
			weeklyId: null,
			DailyCharsData: [{
				datetime: '2021-09-11 ~ 2021-09-18',
				Data: yData,
				alarm: [{
					name: '低血糖',
					time: "6:32pm",
					alarmgrade: 1,
				}, {
					name: '高血糖',
					time: "8:54pm",
					alarmgrade: 2,
				}, {
					name: '蓝牙断开连接',
					time: "12:32pm",
					alarmgrade: 3,
				}]
			}, {
				datetime: '2021-09-19 ~ 2021-09-24',
				Data: yData,
				alarm: [{
					name: '高血糖',
					time: "8:54pm",
					alarmgrade: 2,
				}]
			}],
			loading: true,

			weeklyData: []
		}
	},
	props: {
		puid: String
	},
	created() {
		window.addEventListener('resize', this.innerWidth);
		this.getWidth();
		this.getSearchTime();
		this.getxAxisDataAll();
	},
	destroyed() {
		window.removeEventListener('resize', this.innerWidth)
	},
	methods: {
		getWidth() {
			this.Width = window.innerWidth - 282 + 'px';
		},
		doPDFExport() {
			document.querySelector('.el-collapse-item__wrap').style.display = 'block';
			document.querySelector('.el-tabs__header').style.display = 'none';
			document.querySelector('.pdfshow').style.display = 'none';
			document.querySelector('.pdftitle').style.display = 'block';
			console.log(document.querySelector('#forPDF'))
			this.$PDFSave(document.querySelector('#forPDF'), '每周叠加');
			document.querySelector('.el-collapse-item__wrap').style.display = 'none';
			document.querySelector('.pdfshow').style.display = 'block';
			document.querySelector('.pdftitle').style.display = 'none';
			document.querySelector('.el-tabs__header').style.display = 'block';
		},
		//x轴整体数据
		getxAxisDataAll() {
			var xAxisData = [];
			var xMinute = [];
			var xHour = [];
			for (var min = 0; min < 60; min++) {
				var setMin = min + '';
				if (setMin < 10) {
					setMin = '0' + setMin
				}
				xMinute.push(setMin)
			}
			for (var hour = 0; hour < 24; hour++) {
				var setHour = hour + '';
				if (setHour < 10) {
					setHour = '0' + setHour
				}
				xHour.push(setHour);
			}
			for (var h = 0; h < 24; h++) {
				for (var s = 0; s < 60; s++) {
					var setTime = xHour[h] + ":" + xMinute[s]
					xAxisData.push(setTime);
				}
			}
			this.xAxisDataAll = xAxisData;
		},
		//默认选择90天日期
		getSearchTime() {
			let nowDate = new Date();
			let endTime = parseDate(nowDate);
			let startTime = parseDate(new Date(nowDate.setDate(nowDate.getDate() - 13)));
			this.searchTime = [startTime, endTime];
			this.doSearchTime();
		},
		//点击查询按钮按照日期查询
		doSearchTime() {
			let that = this;
			const entMeasureTime = new Date(Date.parse(that.searchTime[1].replace(/-/g, "/")));
			const startMeasureTime = new Date(Date.parse(that.searchTime[0].replace(/-/g, "/")));
			that.iDays = parseInt(Math.abs(entMeasureTime.getTime() - startMeasureTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;
			let params = {
				userUuid: that.puid,
				endMeasureTime: that.searchTime[1] + ' 23:59:59',
				startMeasureTime: that.searchTime[0] + ' 00:00:00',
			}

			console.log("params", params);

			this.weeklyData.splice(0, this.weeklyData.length); //清空数组
			this.$data.weeklyData = Object.assign([], this.$data.weeklyData); //清空对象
			that.$axios.post('/api/web/patientCgmData/findByWeekSuperposition', params).then(res => {
				if (res.data.code === 200) {
					console.log('res', res.data);

					for (let i = res.data.data.length - 1; i > 0; i--) {
						if (res.data.data[i].cgmDataList != null) {
							that.weeklyData.push(res.data.data[i]);
						}
					}
					this.loading = false
					console.log('that.weeklyData', that.weeklyData);
					this.$nextTick(() => {
						for (let j = 0; j < that.weeklyData.length; j++) {
							that.weeklyId = 'weeklyId' + [j];
							that.initchart(that.weeklyData[j]);
						}
					});
				}
			}).catch(error => {
				// 请求失败
				console.log(error)
				/* this.$message({
					message: "请求失败",
					type: "error"
				}); */
			})
		},
		initchart(arr) {
			var that = this;
			that.chartInstance = that.$echarts.init(that.$refs[that.weeklyId][0]);
			let seriesData = that.generateChartSeries(arr.cgmDataList);

			console.log('seriesData', seriesData);

			let initOption = {
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					left: '30',
					right: '30',
				},
				title: {
					text: arr.day,
					top: '10',
				},
				legend: {
					right: '0',
					icon: "roundRect",
					itemGap: 20
				},
				xAxis: {
					type: 'category',
					data: xData,
					boundaryGap: false,
					axisLabel: {
						interval: 0
					},
					axisTick: {
						interval: 60
					}
				},
				yAxis: {
					type: 'value',
					min: 0,
					max: 22,
					interval: 5,
				},
				series: seriesData
			};

			that.chartInstance.setOption(initOption);
			window.addEventListener("resize", () => {
				this.chartInstance.resize();
			});
			that.loading = false;
		},
		dataOrderBy(ddd) {
			let data = ddd.concat([]);
			data = data.sort(function(a, b) {
				return Date.parse(a.time) - Date.parse(b.time);
			});
			return data;
		},
		getMeasureData(aaa) {
			return aaa.map(e => e.glucose);
		},
		generateChartSeries(arr) {
			let ret = [];
			ret.push({
				type: 'line',
				smooth: true,
				data: [],
				//是否显示节点的○符号
				showSymbol: false,
				lineStyle: {
					width: 2,
					color: '#22c9e4'
				},
				markArea: { //标记区域
					data: [
						[{
							yAxis: '3.9',
							itemStyle: {
								color: '#fffb8723'
							}
						}, {
							yAxis: '10'
						}]
					]
				},
				//分割线
				/* markLine: {
					symbolSize: [0, 0],
					silent: true,
					data: [{
						yAxis: 3.9,
						lineStyle: {
							color: '#f33131',
							width: 1.5,
							type: 'solid'
						},
					}, {
						yAxis: 10,
						lineStyle: {
							color: '#f1b907',
							width: 1.5,
							type: 'solid'
						},
					}]
				} */
			});

			let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
			let weeksColor = ['#431993', '#60C6E0', '#43977D', '#8787DE', '#7D37C5', '#2424DB', '#6BE6BE'];
			let ss = [];
			for (let i = 0; i < arr.length; i++) {
				let wk = new Date(arr[i].measureTime).getDay();
				ss.push({
					glucose: arr[i].glucose,
					week: weeks[wk],
					color: weeksColor[wk],
					time: arr[i].measureTime
				});
			}

			let sortFirst = this.dataOrderBy(ss);
			let finalWeek = groupby(sortFirst, 'week');
			console.log('finalWeek', finalWeek);

			for (let j = 0; j < finalWeek.length; j++) {
				ret.push({
					type: 'line',
					name: finalWeek[j][0].week,
					smooth: true,
					data: this.getMeasureData(finalWeek[j]),
					showSymbol: false,
					color: finalWeek[j][0].color,
					lineStyle: {
						width: 2,
						color: finalWeek[j][0].color,
						type: 'solid',
					},
				})
			}
			return ret;
		}
	},
};
