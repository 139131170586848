import {
	xData
} from "../../utils/chartXData.js"
import {
	parseDate
} from "../../utils/convert.js"
export default {
	name: 'Daily',
	data() {
		return {
			pickerOptions: {
				disabledDate(time) {
					let curDate = (new Date()).getTime();
					// 改为默认60天
					let three = 59 * 24 * 3600 * 1000;
					let threeMonths = curDate - three;
					return time.getTime() > Date.now() || time.getTime() < threeMonths;
				},
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近两个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			searchTime: null,
			chartInstance: null,
			//时间选择器选择时间差
			iDays: 60,
			DailyCharsData: [],
			alarmtime: '',
			dailyId: null,
			8.8: null,
			Width: '',
			xAxisDataAll: [],
			tirChart: [],
			yAxisData: [],
			allMinutes: [],
			loading: true,

			// 默认6页，共60天，每页10天
			totalPages:null,
			total:null,
			currPage: 1,
			currSize: 6,
			//报警列表展示，1--收起；2--展开
			//alarmList:true,
		}
	},
	props: {
		puid: String
	},
	created() {
		window.addEventListener('resize', this.innerWidth);
		this.getWidth();
		this.getSearchTime();
		this.getxAxisDataAll();
	},
	destroyed() {
		window.removeEventListener('resize', this.innerWidth)
	},
	methods: {
		getAlarmList(ii) {
			const alarmList = 'alarmList' + ii
			const iconAlarmlist = 'icon_alarmlist' + ii
			const className = document.getElementById(alarmList).className;
			const alarmListShow = className.search('alarmlist_up') != -1;
			if (alarmListShow) {
				document.getElementById(alarmList).classList.add('alarmlist_down')
				document.getElementById(alarmList).classList.remove('alarmlist_up')
				document.getElementById(iconAlarmlist).classList.add('el-icon-arrow-up')
				document.getElementById(iconAlarmlist).classList.remove('el-icon-arrow-down')
			} else {
				document.getElementById(alarmList).classList.add('alarmlist_up')
				document.getElementById(alarmList).classList.remove('alarmlist_down')
				document.getElementById(iconAlarmlist).classList.add('el-icon-arrow-down')
				document.getElementById(iconAlarmlist).classList.remove('el-icon-arrow-up')
			}
		},
		getWidth() {
			this.Width = window.innerWidth - 282 + 'px';
		},
		handleSizeChange(newSize) {
			this.currSize = newSize;
		},
		handleCurrentChange(newPage) {
			this.loading = true;
			this.currPage = newPage;

			let endTime = new Date(this.searchTime[1]);
			endTime.setDate(endTime.getDate() - (9 * (newPage - 1)));
			let startTime = new Date(endTime);
			startTime.setDate(startTime.getDate() - 9);

			this.doSearch(parseDate(startTime), parseDate(endTime));
		},
		//x轴整体数据
		getxAxisDataAll() {
			var xAxisData = [];
			var xMinute = [];
			var xHour = [];
			for (var min = 0; min < 60; min++) {
				var setMin = min + '';
				if (setMin < 10) {
					setMin = '0' + setMin
				}
				xMinute.push(setMin)
			}
			for (var hour = 0; hour < 24; hour++) {
				var setHour = hour + '';
				if (setHour < 10) {
					setHour = '0' + setHour
				}
				xHour.push(setHour);
			}
			for (var h = 0; h < 24; h++) {
				for (var s = 0; s < 60; s++) {
					var setTime = xHour[h] + ":" + xMinute[s]
					xAxisData.push(setTime);
				}
			}
			this.xAxisDataAll = xAxisData;
		},
		getxAxisData() {
			let newDate = new Date();
			let yy = newDate.getFullYear();
			let mm = newDate.getMonth() + 1;
			mm = mm < 10 ? '0' + mm : mm;
			let dd = newDate.getDate();
			dd = dd < 10 ? '0' + dd : dd;
			let forCal = new Date(yy + '-' + mm + '-' + dd + ' 00:00:00');
			let t_s = forCal.getTime();

			for (var ii = 0; ii < this.DailyCharsData.length; ii++) {
				var vm = this;
				vm.$set(this.DailyCharsData[ii], 'allMinutes', []);
				for (let i = 0; i < 1440; i++) {
					forCal.setTime(t_s + 1000 * 60 * i);
					let newHour = forCal.getHours() < 10 ? '0' + forCal.getHours() : forCal.getHours();
					let newMin = forCal.getMinutes() < 10 ? '0' + forCal.getMinutes() : forCal.getMinutes();
					var newtime = this.DailyCharsData[ii].date + ' ' + newHour + ':' + newMin + ':00';
					this.DailyCharsData[ii].allMinutes.push(newtime);
				}
			}
		},
		//默认选择60天日期
		getSearchTime() {
			let nowDate = new Date();
			let nowDate1 = new Date();
			let endTime = new Date();
			// 分页逻辑，默认取10条，就是10天
			let startTime = new Date(nowDate.setDate(nowDate.getDate() - 13));
			let startTimeForSearch = parseDate(new Date(nowDate1.setDate(nowDate1.getDate() - 9)));
			this.searchTime = [parseDate(startTime), parseDate(endTime)];

			this.iDays = parseInt(Math.abs(endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;

			this.doSearch(startTimeForSearch, parseDate(endTime));
		},
		// 排序
		dataOrderBy(ddd) {
			let data = ddd.concat([]);
			data = data.sort(function(a, b) {
				return Date.parse(b.date + ' 00:00:00') - Date.parse(a.date + ' 00:00:00');
			});
			return data;
		},
		// 报警排序
		warningListOrderBy(arr) {
			let data = arr === null ? [] : arr.concat([]);
			if (data.length > 0) {
				data = data.sort(function(a, b) {
					return parseInt(b.level) - parseInt(a.level);
				});
				return data;
			}
			return arr;
		},
		doSearch(s, e) {
			console.log(s);
			console.log(e);

			var params = {
				userUuid: this.puid,
				endCreateTime: e + ' 23:59:59',
				startCreateTime: s + ' 00:00:00',
				page:this.currPage,
				size:this.currSize
			}
			console.log("params", params);
			this.yAxisData.splice(0, this.yAxisData.length); //清空数组
			this.$data.DailyCharsData = Object.assign([], this.$data.DailyCharsData); //清空对象
			this.$axios.post('/api/web/patientCgmWarningLog/newlist', params).then(response => {
				// post 成功，response.data 为返回的数据
				this.DailyCharsData = this.dataOrderBy(response.data.data);
				this.totalPages = response.data.pageData.totalPages;
				this.total = response.data.pageData.totalElements;
				console.log("response.data.data", response.data);
				this.getxAxisData();
				this.$nextTick(() => {
					for (var i = 0; i < this.DailyCharsData.length; i++) {
						this.dailyId = 'dailyId' + [i];
						this.alarmtime = this.DailyCharsData[i].date;
						//var vm = this;
						var allMinutes = this.DailyCharsData[i].allMinutes;
						var yAxisData = new Array(1440);
						if (this.DailyCharsData[i].listCgmData !== null) {
							this.DailyCharsData[i].listCgmData.forEach(item1 => {
								for (var setnum = 0; setnum < 1440; setnum++) {
									if (item1.measureTime == allMinutes[setnum]) {
										yAxisData[setnum] = item1.glucose;
									}
								}
								this.DailyCharsData[i].yAxisData = yAxisData;
							})
						}
						this.yAxisData = this.DailyCharsData[i].yAxisData
						this.initchart();
					}
					this.loading = false;
				});
			}).catch(error => {
				// 请求失败
				console.log(error)
				/* this.$message({
					message: "请求失败",
					type: "error"
				}); */
			})
		},
		//点击查询按钮按照日期查询
		doSearchTime() {
			this.loading = true;
			const entMeasureTime = new Date(Date.parse(this.searchTime[1].replace(/-/g, "/")));
			const startMeasureTime = new Date(Date.parse(this.searchTime[0].replace(/-/g, "/")));
			this.iDays = parseInt(Math.abs(entMeasureTime.getTime() - startMeasureTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;

			let forCal = new Date(this.searchTime[1].replace(/-/g, "/"));
			let forCal0 = new Date(this.searchTime[0].replace(/-/g, "/"));
			let startTimeForSearch = parseDate(new Date(forCal.setDate(forCal.getDate() - 9)))
			let startTimeCal0 = new Date(forCal0.setDate(forCal0.getDate()))
			let startTimeCal1 = new Date(forCal.setDate(forCal.getDate() - 9))

			var params = {
				userUuid: this.puid,
				endCreateTime: this.searchTime[1] + ' 23:59:59',
				// startCreateTime: this.searchTime[0] + ' 00:00:00',
				startCreateTime: null,
				page:this.currPage,
				size:this.currSize
			};
			if (startTimeCal1 > startTimeCal0) {
				params.startCreateTime = startTimeForSearch + ' 00:00:00';
			} else {
				params.startCreateTime = this.searchTime[0] + ' 00:00:00';
			}
			this.doSearch(params.startCreateTime,params.startCreateTime)
			/* console.log("params", params)
			this.total = this.iDays;
			this.currPage = 1;
			this.currSize = 10;

			/* this.yAxisData.splice(0, this.yAxisData.length); //清空数组
			this.$data.DailyCharsData = Object.assign([], this.$data.DailyCharsData); //清空对象
			console.log(params)
			this.$axios.post('/api/web/patientCgmWarningLog/newlist', params).then(response => {
				// post 成功，response.data 为返回的数据
				this.DailyCharsData = this.dataOrderBy(response.data.data);
				this.totalPages = response.data.pageData.totalPages;
				this.total = response.data.pageData.totalElements;
				console.log(this.DailyCharsData)
				this.getxAxisData();
				this.$nextTick(() => {
					for (var i = 0; i < this.DailyCharsData.length; i++) {
						this.dailyId = 'dailyId' + [i];
						this.alarmtime = this.DailyCharsData[i].date;
						//var vm = this;
						var allMinutes = this.DailyCharsData[i].allMinutes;
						var yAxisData = new Array(1440);
						if (this.DailyCharsData[i].listCgmData !== null) {
							this.DailyCharsData[i].listCgmData.forEach(item1 => {
								for (var setnum = 0; setnum < 1440; setnum++) {
									if (item1.measureTime == allMinutes[setnum]) {
										yAxisData[setnum] = item1.glucose;
									}
								}
								this.DailyCharsData[i].yAxisData = yAxisData;
							})
						}
						this.yAxisData = this.DailyCharsData[i].yAxisData
						this.initchart();
					}
					this.loading = false
				});
			}).catch(error => {
				// 请求失败
				console.log(error)
				this.$message({
					message: "请求失败",
					type: "error"
				});
			}) */
		},
		initchart() {
			this.chartInstance = this.$echarts.init(document.getElementById(this.dailyId));
			const initOption = {
				tooltip: {
					trigger: 'axis'
				},
				grid: {
					left: '30',
					right: '30',
				},
				title: {
					text: this.alarmtime,
					top: '10',
				},
				xAxis: {
					type: 'category',
					data: xData,
					boundaryGap: false,
					axisLabel: {
						interval: 0
					},
					axisTick: {
						interval: 60
					}
				},
				yAxis: {
					type: 'value',
					min: 0,
					max: 22,
					interval: 5,
				},
				//线的颜色样式
				visualMap: {
					top: 500,
					type: 'piecewise',
					//symbolSize: 80,
					pieces: [{
						gt: 0.1,
						lte: 3.9,
						color: '#FF6363',
					}, {
						gt: 3.9,
						lte: 10,
						color: '#4870FF',

					}, {
						gt: 10,
						color: '#FFF585',
					}],
				},
				series: [{
					name: '血糖值',
					type: 'line',
					smooth: true,
					data: this.yAxisData,
					//是否显示节点的○符号
					showSymbol: false,
					lineStyle: {
						width: 2
					},
					markArea: { //标记区域
						data: [
							[{
								yAxis: '3.9',
								itemStyle: {
									color: '#4870FF23'
								}
							}, {
								yAxis: '10'
							}]
						]
					},
					//分割线
					/* markLine: {
						symbolSize: [0, 0],
						silent: true,
						data: [{
							yAxis: 3.9,
							lineStyle: {
								color: '#f33131',
								width: 1.5,
								type: 'solid'
							},
						}, {
							yAxis: 10,
							lineStyle: {
								color: '#f1b907',
								width: 1.5,
								type: 'solid'
							},
						}]
					}, */
				}]
			};
			this.chartInstance.setOption(initOption);
			window.addEventListener("resize", () => {
				this.chartInstance.resize();
			});
			this.loading = false;
		},
	},
};
